<template>
	<div class="gg-container">
		<div class="search-container">
			<!--输入框-->
			<div class="search-container-fn-input">
				<label>search01：</label>
				<el-input
					style="width:200px"
					size="small"
					placeholder="search01"
					prefix-icon="el-icon-search"
					v-model="searchParams.search"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>
			<div class="search-container-fn-input">
				<label>select01：</label>
				<el-select
					style="width:200px"
					v-model="searchParams.select"
					placeholder="select01"
					size="small"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search-container-fn-input">
				<label>dateTime01：</label>
				<el-date-picker
					v-model="searchParams.timeValue"
					type="daterange"
					size="small"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="getList('restPage')">
				</el-date-picker>
			</div>
			
			<!--按钮样式-->
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlebtn01('新增')">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">新增</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handlebtn02(scope.$index, scope.row)">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">上传</span>
					</el-button>
				</div>
			</div>
		
		</div>
		
		<!----------------表格---------------->
		<el-table
			border
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			style="width: 100%;z-index: 0">
			<el-table-column
				fixed
				label="ID"
				type="index"
				align="center"
				width="80">
			</el-table-column>
			<el-table-column
				fixed
				prop="date"
				label="商品名称"
				align="center">
			</el-table-column>
			<el-table-column
				label="选择"
				align="center">
				<template slot-scope="scope">
					<div style="display: flex; justify-content: space-around">
						<el-select v-model="value" placeholder="请选择" size="mini">
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-button size="mini" type="success" icon="el-icon-upload2"
											 style="margin-right: 10px; padding: 7px 8px;"
											 @click="handleUp(scope.$index, scope.row)">确认
						</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="status"
				align="center"
				label="商品状态">
				<template slot-scope="scope">
					<el-switch
						class="switch"
						@change="changeExamine(scope.row)"
						v-model="scope.row.status"
						:active-value="1"
						:inactive-value="0"
						active-text="启用"
						inactive-text="禁用"
						active-color="#13ce66"
						inactive-color="#C8C8C8">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column
				prop="name"
				label="排序"
				align="center">
			</el-table-column>
			<el-table-column align="center" width="200" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" icon="el-icon-edit"
										 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleTable01(scope.$index, scope.row)">编辑
					</el-button>
					<el-button size="mini" type="danger" icon="el-icon-delete"
										 style="padding: 7px 8px;margin-left: 5px;"
										 @click="handleTable02(scope.$index, scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		<!----------------分页---------------->
		<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>
	
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'

export default {
  name: "SingInputAndTable",
  components: {
    Pagination,
  },
  created () {
  },
  data () {
    return {
      searchParams: {},
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
    }
  },
  methods: {
    test () {
    },
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.page_size = this.listQuery.limit
      this.test(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          this.getPayCount()
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    changeExamine (row) {  //选择状态 (启用/禁用)
      console.log(row.appraise_id)
      console.log(row.status)
      this.listLoading = true
      this.test().then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
